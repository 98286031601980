import '/src/css/style.pcss'

tsParticles
    .loadJSON("tsparticles", "/particlesjs-config.json")
    .then(container => {
        console.log("callback - tsparticles config loaded");
    })
    .catch(error => {
        console.error(error);
    });

/**
 * Alpine configuration
**/
import Alpine from 'alpinejs'

// Alpine start
Alpine.start();

const logoWrapper = document.getElementById('logo-wrapper');
setInterval(() => {
  logoWrapper.classList.add('logo-wink');
  setTimeout(() => {
      logoWrapper.classList.remove('logo-wink');
  }, 1000);
}, 6000);

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
**/ 
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
  }
